export const weekDays = [
    { "option": "All Week", "label": "All Week" },
    { "option": "First Week Half", "label": "First Week Half" },
    { "option": "Weekend", "label": "Weekend" },
    { "option": "Monday", "label": "Monday" },
    { "option": "Tuesday", "label": "Tuesday" },
    { "option": "Wednesday", "label": "Wednesday" },
    { "option": "Thursday", "label": "Thursday" },
    { "option": "Friday", "label": "Friday" },
    { "option": "Saturday", "label": "Saturday" },
    { "option": "Sunday", "label": "Sunday" }
]